import { useEmployerStore } from "@/store/employer";
import { useCompanyStore } from "@/store/company";
import type { ContactModal } from "@/types";

export const useSupportStore = defineStore("support", () => {
  const contactSupportModal = ref<ContactModal>({
    active: false,
    message: "",
  });

  const contactSalesModal = ref<ContactModal>({
    active: false,
    message: "",
  });

  const employerStore = useEmployerStore();
  const companyStore = useCompanyStore();

  const { employer } = storeToRefs(employerStore);
  const { company } = storeToRefs(companyStore);

  const validateRequest = () => {
    if (!company.value || !employer.value) throw Error(ERROR_MESSAGES.company_not_found);
    if (!employer.value) throw Error(ERROR_MESSAGES.employer_not_found);
  };

  const sendContactEmail = async (endpoint: "support" | "sales", modal: Ref<ContactModal>) => {
    validateRequest();

    await $api<{ success: boolean }>(`${API_ROUTES.contact}/${endpoint}`, {
      method: "post",
      body: {
        message: modal.value.message,
        employer: { ...employer.value, company: company.value },
      },
    });

    toggleModal(modal, false);
    setTimeout(() => (modal.value.message = ""), 300);
  };

  const sendContactSupportEmail = () => sendContactEmail("support", contactSupportModal);

  const sendContactSalesEmail = () => sendContactEmail("sales", contactSalesModal);

  const toggleModal = (modal: Ref<ContactModal>, active: boolean, message?: string) => {
    modal.value.message = message ?? "";
    modal.value.active = active;
  };

  const toggleContactSupportModal = ({ active, message }: { active: boolean; message?: string }) => {
    toggleModal(contactSupportModal, active, message);
  };

  const toggleContactSalesModal = ({ active, message }: { active: boolean; message?: string }) => {
    toggleModal(contactSalesModal, active, message);
  };

  return {
    sendContactSupportEmail,
    sendContactSalesEmail,
    contactSupportModal,
    contactSalesModal,
    toggleContactSupportModal,
    toggleContactSalesModal,
  };
});
